import React from 'react';
import { graphql } from 'gatsby';

import { useLanguage } from '../hooks/useLanguage';
import { Language } from '../services/Language';
import StaticPage from '../views/Layout/StaticPage';
import { PrivacyPolicyContentCsHtmFiltered } from '../views/PrivacyPolicyContentCsHtmFiltered';
import { PrivacyPolicyContentEnHtmFiltered } from '../views/PrivacyPolicyContentEnHtmFiltered';
import { PrivacyPolicyContentSkHtmFiltered } from '../views/PrivacyPolicyContentSkHtmFiltered';
import T, { useTranslation } from '../views/Translate';

import styles from './privacyPolicy.module.scss';

interface Link {
  text: string;
  link: string;
  postfix?: string;
}

export const query = graphql`
  query ($language: String!) {
    locale(language: { eq: $language }, ns: { eq: "privacyPolicy" }) {
      data
    }
  }
`;

export default function PrivacyPolicy({ data }) {
  const { t } = useTranslation();
  const jsonData = JSON.parse(data.locale.data);
  const links = jsonData['links'] as Link[];
  const lang = useLanguage();

  return (
    <StaticPage className="my-5" pageview="privacy_policy" title={t('privacyPolicy:title')}>
      <div className="container container--light">
        <div className={styles.sectionContainer}>
          {lang === Language.CS ? (
            <PrivacyPolicyContentCsHtmFiltered />
          ) : lang === Language.SK ? (
            <PrivacyPolicyContentSkHtmFiltered />
          ) : (
            <PrivacyPolicyContentEnHtmFiltered />
          )}
        </div>

        <h3 className="center">
          <T id="privacyPolicy:subtitle" />
        </h3>

        <ul className="mb-3" style={{ fontSize: '140%', paddingLeft: '1em', color: '#7b7d7f' }}>
          {links.map((link, index) => (
            <li key={`link-${index}`}>
              <a href={link.link} rel="noopener noreferrer" target="_blank">
                {link.text}
              </a>
              {link.postfix ?? ''}
            </li>
          ))}
        </ul>
      </div>
    </StaticPage>
  );
}
