import React from 'react';

/* eslint-disable */
export function PrivacyPolicyContentEnHtmFiltered() {
	return (
		<>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="Generator" content="Microsoft Word 15 (filtered)" />
			<div className="WordSection1">
				<p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
					<span className="Heading1Char">
						<span
							lang="EN-GB"
							style={{ fontSize: "24.0pt", lineHeight: "104%" }}
						>
							Privacy Policy
						</span>
					</span>
				</p>
				<p className="MsoNormal">
					<span lang="EN-GB">
						In this document, you will find in particular information about what
						personal data we collect, why we process it, how long we keep it and
						what privacy rights you can exercise.{" "}
					</span>
				</p>
				<h1>
					<span lang="EN-GB">
						A.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
					</span>
					<span lang="EN-GB">JOINT DATA CONTROLLERS </span>
				</h1>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						As the HoppyGo platform operates in different countries, we need to
						share the necessary information within our affiliates so that you
						can use our services uninterrupted wherever you are.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">Who manages your data:</span>
				</p>
				<table
					className="TableGrid"
					border={0}
					cellSpacing={0}
					cellPadding={0}
					width={607}
					style={{
						width: "455.1pt",
						marginLeft: "-.8pt",
						borderCollapse: "collapse",
					}}
				>
					<tbody>
						<tr style={{ height: "37.2pt" }}>
							<td
								width={293}
								valign="top"
								style={{
									width: "220.05pt",
									border: "solid black 1.0pt",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "37.2pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">HoppyGo s.r.o.,&nbsp; </span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span
										lang="EN-GB"
										style={{ fontSize: "10.0pt", lineHeight: "104%" }}
									>
										Identification number: 06628664{" "}
									</span>
								</p>
							</td>
							<td
								width={313}
								valign="top"
								style={{
									width: "234.8pt",
									border: "solid black 1.0pt",
									borderLeft: "none",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "37.2pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "6.6pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">HoppyGo platform owner </span>
								</p>
							</td>
						</tr>
						<tr style={{ height: "37.0pt" }}>
							<td
								width={293}
								valign="top"
								style={{
									width: "220.05pt",
									border: "solid black 1.0pt",
									borderTop: "none",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "37.0pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">HoppyGo Czechia s.r.o.,&nbsp; </span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span
										lang="EN-GB"
										style={{ fontSize: "10.0pt", lineHeight: "104%" }}
									>
										identification number: 07799586
									</span>
								</p>
							</td>
							<td
								width={313}
								valign="top"
								style={{
									width: "234.8pt",
									borderTop: "none",
									borderLeft: "none",
									borderBottom: "solid black 1.0pt",
									borderRight: "solid black 1.0pt",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "37.0pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "6.6pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">
										Data controller for the Czech Republic{" "}
									</span>
								</p>
							</td>
						</tr>
						<tr style={{ height: "26.2pt" }}>
							<td
								width={293}
								valign="top"
								style={{
									width: "220.05pt",
									border: "solid black 1.0pt",
									borderTop: "none",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "26.2pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">HoppyGo Poland SP. Z o o,&nbsp; </span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span
										lang="EN-GB"
										style={{ fontSize: "10.0pt", lineHeight: "104%" }}
									>
										Identification number: 0000818732{" "}
									</span>
								</p>
							</td>
							<td
								width={313}
								valign="top"
								style={{
									width: "234.8pt",
									borderTop: "none",
									borderLeft: "none",
									borderBottom: "solid black 1.0pt",
									borderRight: "solid black 1.0pt",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "26.2pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "6.6pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">Data controller for Poland </span>
								</p>
							</td>
						</tr>
						<tr style={{ height: "26.2pt" }}>
							<td
								width={293}
								valign="top"
								style={{
									width: "220.05pt",
									border: "solid black 1.0pt",
									borderTop: "none",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "26.2pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: "0cm",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",

										lineHeight: "normal",
										verticalAlign: "baseline",
									}}
								>
									<span lang="CS">HoppyGo Slovakia s.r.o.</span>
									<span lang="EN-GB">&nbsp;</span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "7.1pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span
										lang="EN-GB"
										style={{ fontSize: "10.0pt", lineHeight: "104%" }}
									>
										Identification number
									</span>
									<span
										lang="CS"
										style={{ fontSize: "10.0pt", lineHeight: "104%" }}
									>
										: 53514106
									</span>
									<span
										lang="EN-GB"
										style={{ fontSize: "10.0pt", lineHeight: "104%" }}
									>
										&nbsp;
									</span>
								</p>
							</td>
							<td
								width={313}
								valign="top"
								style={{
									width: "234.8pt",
									borderTop: "none",
									borderLeft: "none",
									borderBottom: "solid black 1.0pt",
									borderRight: "solid black 1.0pt",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "26.2pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginTop: "0cm",
										marginRight: ".05pt",
										marginBottom: "0cm",
										marginLeft: "6.6pt",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="CS" style={{ lineHeight: "104%" }}>
										Data controller for Slovakia
									</span>
									<span lang="EN-GB" style={{ lineHeight: "104%" }}>
										&nbsp;
									</span>
								</p>
							</td>
						</tr>
						<tr style={{ height: "26.8pt" }}>
							<td
								width={293}
								valign="top"
								style={{
									width: "220.05pt",
									border: "none",
									background: "white",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "26.8pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginLeft: "0cm",
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">&nbsp;</span>
								</p>
							</td>
							<td
								width={313}
								valign="top"
								style={{
									width: "234.8pt",
									border: "none",
									background: "white",
									padding: "2.7pt 5.75pt 0cm .55pt",
									height: "26.8pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										textAlign: "justify",
										textJustify: "inter-ideograph",
									}}
								>
									<span lang="EN-GB">&nbsp;</span>
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						Specifically, we share data as follows. In the event that you
						register as a user of the HoppyGo platform, HoppyGo s.r.o., which is
						the administrator of the HoppyGo platform, becomes the controller of
						your personal data, as well as HoppyGo of the country in which you
						register.&nbsp; So, for example, in case you create your first
						registration in the Czech Republic, HoppyGo s.r.o. will be the joint
						controller of your personal data with HoppyGo Czechia s.r.o.&nbsp;{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						HoppyGo companies from other countries will not have access to your
						personal data. In the event that you log into the HoppyGo platform
						from a country other than the country of your initial registration,
						and in the event that you wish to rent a car through the HoppyGo
						platform in a country other than the country of your initial
						registration, the HoppyGo company from that other country will also
						become a joint controller of your personal data. This is so that we
						can provide you with our services there as well.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						The processing takes place as part of the following activities:{" "}
					</span>
				</p>
				<p className="MsoNormal">
					<span lang="EN-GB">&nbsp;</span>
				</p>
				<h1>
					<span lang="EN-GB">
						B.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						&nbsp;DESCRIPTION OF THE PERSONAL DATA PROCESSING
					</span>
				</h1>
				<h2>
					<span lang="EN-GB">
						1.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-GB">
						REGISTRATION OF THE USER AND OWNER OF THE VEHICLE
					</span>
				</h2>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Description of the purpose of processing </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process personal data you provide in order to register you as a
						user of the HoppyGo platform or as a vehicle owner and to enable you
						to rent or offer vehicles. We will also verify the information
						provided by users or vehicle owners in order to assess compliance
						with the terms of service. The check includes verification of users'
						information in the executions register and insolvency records to
						protect the rights of vehicle owners and HoppyGo and to prevent bad
						debts. As part of the registration process, we will automatically
						process information from your identity documents and photographs to
						establish and verify your identity, to defend the legal claims of us
						and our customers and to prevent fraud where appropriate. This
						processing includes automated text reading, document and photo
						authentication, including the evaluation of biometric indicators.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We have the right to cancel an application for registration or even
						an already approved registration, in particular in the event of the
						discovery of executions or insolvency proceedings. Following the
						submission of your registration application, we may require you as
						the owner of the vehicle to provide additional documents in addition
						to the registration information, such as consent from the owner or
						co-owners of the vehicle you wish to lease through the HoppyGo
						platform, if applicable. We may additionally request this
						information from users already registered as owners.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Description of the legal basis for processing
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We carry out the processing of the registration data for the
						conclusion and performance of the contract. We verify information in
						registers and records on the basis of our legitimate interest in
						operating a transparent, safe and reliable platform and to check the
						legal prerequisites for using our services. If, in relation to
						certain personal data, explicit consent to processing is required by
						specific legislation, we only process your personal data if you have
						given your consent (this applies to the processing of data from
						uploaded documents including biometric data). However, where the
						information is necessary for the conclusion or performance of a
						contract, this consent is a condition for the approval of the
						registration.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Categories of personal data we process </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Identification data; contact details such as email address and
						telephone number; in the case of registration via a social media
						profile, your name, profile photo and registered email; copy of
						driving licence; payment and transaction details; descriptive data
						relating to the service; communications relating to the service,
						vehicle data - e.g. make and model of vehicle, date of manufacture,
						VIN, number plate, mileage etc.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						For the purpose of identity verification from the provided document,
						identification and contact data are processed - the exact scope
						depends on the specific document, but usually includes name, gender,
						date of birth (age), identification number (if indicated on the
						document), nationality, citizenship, residential address,
						information about the document - type of document, country of issue,
						number, validity, machine-readable identifiers, security features,
						or other information indicated on the document; photo information -
						biometric indicators from the photo (multidimensional face vectors),
						technical data - user identifier, time stamp, IP address and domain,
						location (city, country), information on the device (camera) and
						software used for authentication.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Duration of processing </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process personal data for the duration of the contract and
						subsequently subset of data is archived to defend our rights based
						on legitimate interest.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Categories of other processors or recipients to whom we may disclose
						personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Your personal data may be disclosed to the extent necessary to our
						business partners providing IT and other administrative and business
						support services.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Identity verification from the provided document is carried out by
						Sum and Substance Ltd (based in the UK). More about this processing{" "}
						<a href="https://sumsub.com/privacy-notice/">here</a>.{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We also work with a company Credit Check s.r.o. to check executions
						and insolvency registers and records.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Origin of personal data</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Directly from you, from publicly available registers, particularly
						commercial, execution and insolvency registers.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Transfer of personal data to third countries or international
						companies{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						In the context of the above processing, with the exception of
						cooperation with Sum and Substance Ltd in the UK, your personal data
						will not be transferred to third countries outside the European
						Economic Area ("EEA") or to international organisations.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Automated decision making based on personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						There is no automated data processing involved in this processing.
					</span>
				</p>
				<h2>
					<span lang="EN-GB">
						2.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-GB">
						VEHICLE RENTAL, TRANSACTIONS AND PAYMENT PROCESSING
					</span>
				</h2>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Description of the purpose of processing </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process the data in order to be able to process vehicle rental
						requests and to connect users with owners. This also requires that
						certain data (including data in the form of ratings from other
						users) is made available to other users of the platform in their
						role as drivers or owners, in particular your basic identification
						data (name and surname), vehicle data or other data that you have
						voluntarily provided to us. Other personal data you provide (more
						detailed identification, contact and transaction data) will only be
						disclosed if you are interested in concluding a rental agreement.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						When you use one of the offered vehicles, we then process
						information related to your ride. Vehicles from the HoppyGo fleet
						are equipped with location tracking so that you can easily find and
						take possession of them.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Description of the legal basis for processing
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						The processing is carried out to enable users to benefit from
						vehicle rental services. The provision of personal data is a
						contractual requirement necessary for the provision of services.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Categories of personal data we process </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Identification data; contact data; descriptive data; communication
						with other users of the platform; user ratings; vehicle location
						data if you rent our own vehicle; data on the availability of the
						rental vehicle; transaction and payment data; mileage, information
						on possible offences and traffic accidents; data on the current
						state of the vehicle (e.g. cleanliness of the vehicle, technical
						condition of the vehicle, etc.).
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Duration of the processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process the personal data for the duration of the contract and
						subsequently subset of data is archived to defend our rights based
						on legitimate interest (until any claims are time-barred).
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Categories of other processors or recipients to whom we may disclose
						personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Uniqa pojišt'ovna a.s., other users of the platform (only to the
						extent necessary), business partners providing IT and other
						administrative and business support services. Public authorities, in
						particular courts, police and other law enforcement and offence
						resolution authorities to the extent necessary and within the limits
						of the law.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Origin of personal data </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Directly from you or your use of the service.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Transfer of personal data to third countries or international
						companies{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						As part of the processing described above, your personal data will
						not be transferred to third countries outside the EEA or to
						international organisations.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Automated decision-making based on personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						There is no such processing in the context of this processing.
					</span>
				</p>
				<h2>
					<span lang="EN-GB">
						3.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-GB">ARCHIVING OF PERSONAL DATA </span>
				</h2>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Description of the purpose of processing </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						If the contract for the procurement of the rental opportunity you
						have concluded with us is terminated, e.g. also by cancelling your
						registration (whether as owner or driver), we will continue to
						process your personal data after cancellation of your account to the
						extent necessary for our protection against any claims related to
						us. Similarly, we will retain data in the event that you have
						applied to register as an owner user and your application has been
						rejected or your account has been terminated on our initiative after
						your registration (whether as an owner or driver).
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Description of the legal basis for processing
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We have a legitimate interest in carrying out this processing. This
						legitimate interest consists of defending ourselves against any
						claims made against us.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Categories of personal data we process </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Identification data; contact data; descriptive data; communication
						with other users of the platform and us; location data (e.g. if you
						do not return the rented car at the agreed time); transaction and
						payment data; vehicle status data, data for which registration has
						been refused or user account has been cancelled.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Processing and archiving time </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We store the data for the time necessary to protect our rights, i.e.
						until the expiry of the statute of limitations on any claims or
						their final settlement.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Categories of other processors or recipients to whom we may disclose
						personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Your personal data may be processed to the extent necessary by our
						business partners providing IT and other administrative and support
						services or provided to public authorities, in particular to courts,
						police and other law enforcement authorities and authorities dealing
						with misdemeanours, upon request, to the extent necessary and within
						the limits of the law.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Origin of personal data </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Directly from you or your use of the service.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Transfer of personal data to third countries or international
						companies{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						As part of the processing described above, your personal data will
						not be transferred to third countries outside the EEA or to
						international organisations.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Automated decision-making based on personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						There is no such processing in the context of this processing.
					</span>
				</p>
				<h2>
					<span lang="EN-GB">
						4.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-GB">
						SENDING COMMERCIAL OFFERS OF OUR PRODUCTS AND SERVICES AND INFORMING
						ABOUT NEW PRODUCTS AND SERVICES{" "}
					</span>
				</h2>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Description of the purpose of processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process your data for the purpose of informing you about
						commercial offers of our products and services and about news
						related to our activities.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Description of the legal basis for processing
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						The processing is based on a legitimate interest in communicating
						up-to-date information and offering you further information about
						our products and services that are relevant to you and may be of
						interest to you.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Categories of personal data we process </span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Identification data; contact data; account data; service usage data;
						data on available vehicles, reviews and related communications.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Duration of processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process your personal data for the duration of your contractual
						relationship with us, or until you unsubscribe from the
						newsletter.&nbsp;{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Categories of other processors or recipients to whom we may disclose
						personal data
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Marketing agencies, analytical and statistical companies.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Origin of personal data</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Directly from you or from the use of our services.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Transfer of personal data to third countries or international
						companies{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						As part of the processing described above, your personal data will
						not be transferred to third countries outside the EEA or to
						international organisations.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Automated decision-making based on personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						There is no such processing in the context of this processing.
					</span>
				</p>
				<h2>
					<span lang="EN-GB">
						5.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-GB">
						QUALITY ASSURANCE OF SERVICES, PRODUCTION OF ANALYSES, REPORTS AND
						STATISTICS
					</span>
				</h2>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Description of the purpose of processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						In order to properly ensure the functioning of the services we
						provide and to improve them, we need to process information about
						their use, including personal data, for the creation of analyses,
						reports and statistics. If the contract with you is terminated, for
						example by cancelling your registration, we will continue to process
						your personal data, but only to the minimum extent necessary for the
						production of reports and statistics.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Description of the legal basis for processing
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We have a legitimate interest in carrying out this processing. This
						legitimate interest is to ensure the proper functioning of the
						services we provide and to improve their quality.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Categories of personal data we process</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Identification data; contact data; platform account data; vehicle
						information; service usage data; ratings and related communications
						including recorded customer support calls; internal control and
						investigation data; location data; network identifiers.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Duration of processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process your personal data for the duration of your contractual
						relationship with us and for a period of 3 years after the end of
						the contract. We use telephone calls to check the quality of the
						services provided for a period of 3 months.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Categories of other processors or recipients to whom we may disclose
						personal data
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						IT infrastructure provider (Spinoco Czech Republic, a.s.),
						analytical and statistical companies.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Origin of personal data</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Directly from you or from your use of the platform.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Transfer of personal data to third countries or international
						companies{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						As part of the processing described above, your personal data will
						not be transferred to third countries outside the EEA or to
						international organisations.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Automated decision-making based on personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						There is no such processing in the context of this processing.
					</span>
				</p>
				<h2>
					<span lang="EN-GB">
						6.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-GB">COMPLIANCE WITH OUR LEGAL OBLIGATIONS</span>
				</h2>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Description of the purpose of the processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We are subject to obligations under selected legal provisions that
						we must comply with, which include processing of your personal data.
						We will therefore process your personal data, but only to the extent
						necessary and only for the period defined below.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Description of the legal basis for processing
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process your personal data on the basis of legal obligations, in
						particular in the field of tax and accounting.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Categories of personal data we process</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Identification data; contact data; account data within the service;
						data on the use of the service (transactions and payments).
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Duration of the processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process your personal data for as long as we are required to
						process it by the relevant legislation.&nbsp;{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Categories of other processors or recipients to whom we may disclose
						personal data
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						External tax, accounting and legal advisors; IT infrastructure
						providers.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Origin of personal data</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Directly from you or from your use of the platform.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Transfer of personal data to third countries or international
						companies{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						As part of the processing described above, your personal data will
						not be transferred to third countries outside the EEA or to
						international organisations.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Automated decision-making based on personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						There is no such processing in the context of this processing.
					</span>
				</p>
				<h2>
					<span lang="EN-GB">
						7.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;
						</span>
					</span>
					<span lang="EN-GB">
						COMPLIANCE WITH OBLIGATIONS TOWARDS THE TAX ADMINISTRATION
					</span>
				</h2>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Description of the purpose of processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						The DAC7 Directive (Council Directive (EU) 2021/514) imposes a legal
						obligation on us to share the data of platform users who derive
						income from the rental of vehicles if the user is resident in an EU
						Member State or if the income is derived from the offer of rental
						vehicles in the EU.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We collect your data to enable us to comply with our legal
						obligations under DAC7.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Description of the legal basis for processing
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We process your personal data on the basis legal obligations.{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Categories of personal data we process</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						The name of the lessor, the lessor's residential or registered
						office address (even if not in the Czech Republic), date of birth,
						VAT number/place of birth, identification number and details of any
						premises, the lessor's bank account, the address where the vehicle
						is usually hired, the lessor's total income from the platform for
						each quarter.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Duration of the processing</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We are obliged by law to archive your personal data for a period of
						10 years.&nbsp;{" "}
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Categories of other processors or recipients to whom we may disclose
						personal data
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						We will share your data with the tax authorities for DAC7 compliance
						purposes. The tax administration will then share this data with the
						tax authorities in the EU member state(s) where you reside and/or
						where your vehicle rental offer is located.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Origin of personal data</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Directly from you or from your use of the platform.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Transfer of personal data to third countries or international
						companies{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						As part of the processing described above, your personal data will
						not be transferred to third countries outside the EEA or to
						international organisations.
					</span>
				</p>
				<h3
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Automated decision-making based on personal data{" "}
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "0cm",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						There is no such processing in the context of this processing.
					</span>
				</p>
				<p className="MsoNormal">
					<span lang="EN-GB">&nbsp;</span>
				</p>
				<h1>
					<span lang="EN-GB">
						C.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">OTHER RECIPIENTS OF DATA</span>
				</h1>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						In addition to the data recipients listed for each of the processing
						purposes, we use the services of:
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						- ŠKODA X s.r.o., ID No.: 05976359. The subject of cooperation is
						mainly administrative, marketing, financial or analytical
						activities.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						In the event that something changes in our company and we transfer
						our business or other activities in whole or in part to another
						person (including assignment of contracts), then such transfer would
						include personal data relating to these activities. For these
						purposes, information (including personal data) relating to our
						business may be shared with other parties in order to evaluate and
						conclude the transaction. This would also be the case if we were
						required by law to make such changes.
					</span>
				</p>
				<p className="MsoNormal">
					<span lang="EN-GB">&nbsp;</span>
				</p>
				<h1>
					<span lang="EN-GB">
						D.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
					</span>
					<span lang="EN-GB">WHAT RIGHTS DO YOU HAVE?</span>
				</h1>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						You have the following rights related to the processing of personal
						data:
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpFirst"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						1.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">Access to the personal data processed.</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						2.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						Withdraw consent to the processing of personal data, if the
						processing is based on consent.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						3.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						Correction of inaccurate or incorrect data or completion of
						incomplete data.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						4.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						Erasure of personal data in case of termination of the purpose or
						unlawful processing. In particular, erasure cannot be carried out if
						the processing is a legal obligation or necessary for the defence of
						our legitimate interests and rights.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						5.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						Restriction of the processing of personal data.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						6.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						Export of specified personal data in a structured and
						machine-readable format for yourself or for another controller.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						7.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						Object to the processing of personal data based on legitimate
						interests if you believe that the processing is not justified.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpLast"
					style={{
						marginLeft: "35.45pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						8.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">
						Not to be subject to automated decision-making unless the conditions
						for it are met. In the event that an automated individual decision
						is made which has substantial consequences for you (e.g. would lead
						to the non-execution of a contract, refusal to pay a benefit, change
						of the terms of a service agreed, etc.), you have the right to have
						such decision reviewed, to comment on it and, where appropriate, to
						challenge it.
					</span>
				</p>
				<h1>
					<span lang="EN-GB">
						E.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">HOW CAN YOU EXERCISE YOUR RIGHTS?</span>
				</h1>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "-.75pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						You can use the following contacts to communicate with controllers
						regarding data protection and to communicate with the Data
						Protection Officer we have appointed:
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "-.75pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">Electronically: dpo(@)hoppygo.com</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "-.75pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">By phone: +420 220 311 769</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "-.75pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">&nbsp;</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginLeft: "-.75pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
					}}
				>
					<span lang="EN-GB">
						Current contacts can be verified at https://hoppygo.com/contact.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						Where requests from a data subject are manifestly unfounded or
						excessive, in particular because of their repetitive character, the
						controller may either: (a) charge a reasonable fee, taking into
						account the administrative costs of providing the information or
						communication or taking the action requested; or (b) refuse to act
						on the request.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">&nbsp;</span>
				</p>
				<h1>
					<span lang="EN-GB">
						F.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;{" "}
						</span>
					</span>
					<span lang="EN-GB">POSSIBILITY OF FILING A COMPLAINT </span>
				</h1>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">
						If you do not agree with the manner by which we process your
						personal data, in order to protect your rights, you can file a
						complaint with the supervisory authority.{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB" style={{ color: "black" }}>
						Address:
					</span>
					<span lang="EN-GB">
						{" "}
						Office for the Protection of Personal Data, Pplk. Sochora 27, 170 00
						Praha 7{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB" style={{ color: "black" }}>
						Telephon:
					</span>
					<span lang="EN-GB">
						{" "}
						+420 234 665 111 <span style={{ color: "black" }}>Web:</span>
						<a href="http://www.uoou.cz/">
							<span style={{ color: "windowtext", textDecoration: "none" }}>
								{" "}
							</span>
						</a>
						<a href="http://www.uoou.cz/">http://www.uoou.cz</a>{" "}
						<a href="http://www.uoou.cz/">&nbsp;</a>
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
				>
					<span lang="EN-GB">&nbsp;</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						textJustify: "inter-ideograph",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="EN-GB"
						style={{ fontFamily: '"Segoe UI",sans-serif', color: "black" }}
					>
						Document version: 2.3
					</span>
				</p>
			</div>
		</>
	);
}
