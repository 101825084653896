import React from 'react';

/* eslint-disable */
export function PrivacyPolicyContentSkHtmFiltered() {
	return (
		<>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="Generator" content="Microsoft Word 15 (filtered)" />

			<div className="WordSection1">
				<h1
					style={{ marginTop: "0pt", marginBottom: "14pt", fontSize: "28pt" }}
				>
					Informácie o spracovaní osobných údajov
				</h1>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "18pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V tomto dokumente sa dozviete, aké údaje zhromažďujeme, prečo ich
						spracúvame, ako dlho ich uchovávame a aké práva na ochranu údajov
						môžete uplatniť.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "24pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							A. Prevádzkovatelia údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "0pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Keďže platforma HoppyGo funguje v rôznych krajinách, potrebujeme
						zdieľať potrebné informácie v rámci našich pobočiek, aby ste mohli
						naše služby využívať bez prerušenia, nech ste s nami kdekoľvek.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "0pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Kto všetko spravuje vaše údaje:
					</span>
				</p>
				<table
					style={{
						marginBottom: "0pt",
						border: "1pt solid #000000",
						borderCollapse: "collapse",
					}}
				>
					<tbody>
						<tr>
							<td
								style={{
									width: "215.5pt",
									borderRight: "1pt solid #000000",
									borderBottom: "1pt solid #000000",
									paddingRight: "4.9pt",
									paddingLeft: "4.9pt",
									verticalAlign: "top",
								}}
							>
								<p
									style={{
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "12pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										HoppyGo Slovakia s.r.o.{" "}
									</span>
								</p>
								<p
									style={{
										marginTop: "14pt",
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "9pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>IČO: 53 514</span>
									<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp;</span>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										106, Tajovského 17, 040 01 Košice - mestská časť Staré Mesto
									</span>
								</p>
							</td>
							<td
								style={{
									width: "215.5pt",
									borderBottom: "1pt solid #000000",
									paddingRight: "4.9pt",
									paddingLeft: "5.4pt",
									verticalAlign: "top",
								}}
							>
								<p
									style={{
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "12pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										Prevádzkovateľ údajov pre Slovensko
									</span>
								</p>
							</td>
						</tr>
						<tr>
							<td
								style={{
									width: "215.5pt",
									borderTop: "1pt solid #000000",
									borderRight: "1pt solid #000000",
									borderBottom: "1pt solid #000000",
									paddingRight: "4.9pt",
									paddingLeft: "4.9pt",
									verticalAlign: "top",
								}}
							>
								<p
									style={{
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "12pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										HoppyGo s.r.o.{" "}
									</span>
								</p>
								<p
									style={{
										marginTop: "14pt",
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "9pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										IČO: 06628664, zapísaná v obchodnom registri vedenom
										Mestským súdom v Prahe pod spisovou značkou C 285761
									</span>
								</p>
							</td>
							<td
								style={{
									width: "215.5pt",
									borderTop: "1pt solid #000000",
									borderBottom: "1pt solid #000000",
									paddingRight: "4.9pt",
									paddingLeft: "5.4pt",
									verticalAlign: "top",
								}}
							>
								<p
									style={{
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "12pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										Manažér platformy HoppyGo
									</span>
								</p>
							</td>
						</tr>
						<tr>
							<td
								style={{
									width: "215.5pt",
									borderRight: "1pt solid #000000",
									paddingRight: "4.9pt",
									paddingLeft: "4.9pt",
									verticalAlign: "top",
								}}
							>
								<p
									style={{
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "12pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										HoppyGo Czechia s.r.o.,{" "}
									</span>
								</p>
								<p
									style={{
										marginTop: "14pt",
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "9pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										IČO: 07799586, zapísaná v obchodnom registri vedenom
										Mestským súdom v Prahe pod spisovou značkou C 307700
									</span>
								</p>
							</td>
							<td
								style={{
									width: "215.5pt",
									paddingRight: "4.9pt",
									paddingLeft: "5.4pt",
									verticalAlign: "top",
								}}
							>
								<p
									style={{
										marginBottom: "0pt",
										textAlign: "justify",
										lineHeight: "normal",
										fontSize: "12pt",
									}}
								>
									<span style={{ fontFamily: '"Segoe UI"' }}>
										Prevádzkovateľ údajov pre Českú republiku
									</span>
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "0pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Konkrétne zdieľame údaje takto. V prípade, že sa zaregistrujete ako
						používateľ platformy HoppyGo, spoločnosť HoppyGo s.r.o., ktorá je
						prevádzkovateľom platformy HoppyGo, sa stáva prevádzkovateľom vašich
						osobných údajov, ako aj HoppyGo krajiny, v ktorej ste sa
						zaregistrovali.
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp; </span>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V prípade, že si vytvoríte prvú registráciu v Slovenskej republike,
						HoppyGo s.r.o. bude spoločným prevádzkovateľom vašich osobných
						údajov s HoppyGo Slovakia s.r.o.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "0pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Spoločnosti HoppyGo z iných krajín nemajú prístup k vašim osobným
						údajom. V prípade, že sa prihlásite na platformu HoppyGo z inej
						krajiny, ako je krajina vašej počiatočnej registrácie, a v prípade,
						že si budete chcieť prenajať auto prostredníctvom platformy HoppyGo
						v inej krajine, ako je krajina vašej počiatočnej registrácie,
						spoločnosť HoppyGo z tejto inej krajiny sa tiež stane spoločným
						prevádzkovateľom vašich osobných údajov. Je to preto, aby sme vám
						mohli poskytovať naše služby aj tam.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "0pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "24pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							B. Opis spracovania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "0pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Súčasťou tejto činnosti je aj spracovanie:
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "0pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp;</span>
				</p>
				<h2 style={{ marginTop: "14pt", marginBottom: "14pt" }}>
					1.
					<span
						style={{
							width: "4.5pt",
							font: '7pt "Times New Roman"',
							display: "inline-block",
						}}
					>
						&nbsp;&nbsp;{" "}
					</span>
					Registrácia užívateľa a vlastníka vozidla
				</h2>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis účelu spracovania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Osobné údaje, ktoré nám poskytnete, spracúvame, aby sme vás mohli
						zaregistrovať ako používateľa platformy HoppyGo alebo ako vlastníka
						vozidla a umožniť vám prenájom alebo ponuku vozidiel. Informácie
						poskytnuté používateľmi alebo vlastníkmi vozidiel tiež overujeme,
						aby sme posúdili dodržiavanie podmienok služby. Kontrola zahŕňa aj
						overenie informácií o používateľoch v exekučných a insolvenčných
						registroch s cieľom chrániť práva vlastníkov vozidiel a HoppyGo a
						predchádzať nedobytným pohľadávkam. V rámci registračného procesu
						automaticky spracúvame informácie z vašich dokladov totožnosti a
						fotografií s cieľom zistiť a overiť vašu totožnosť, brániť právne
						nároky nás a našich zákazníkov a prípadne predchádzať podvodom. Toto
						spracovanie zahŕňa automatické čítanie textu, overovanie dokumentov
						a fotografií vrátane vyhodnocovania biometrických ukazovateľov.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Máme právo zrušiť žiadosť o registráciu alebo už schválenú
						registráciu, najmä v prípade zistenia exekučného alebo konkurzného
						konania. Po podaní žiadosti o registráciu môžeme od vás ako
						vlastníka požadovať, aby ste okrem registračných údajov predložili
						aj ďalšie dokumenty, napríklad súhlas vlastníka alebo
						spoluvlastníkov vozidla, ktoré si chcete prenajať prostredníctvom
						platformy HoppyGo, ak je to potrebné. Tieto informácie môžeme
						dodatočne požadovať od používateľov, ktorí sú už zaregistrovaní ako
						vlastníci.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis právneho základu spracúvania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Spracovanie musíme vykonávať na účely uzavretia a plnenia zmluvy.
						Overovanie informácií v registroch a evidenciách vykonávame na
						základe oprávneného záujmu prevádzkovať transparentnú, bezpečnú a
						spoľahlivú platformu a kontrolovať zákonné predpoklady na využívanie
						našich služieb. Ak sa v súvislosti s niektorými osobnými údajmi
						vyžaduje výslovný súhlas so spracovaním podľa osobitných právnych
						predpisov, vaše osobné údaje spracúvame len vtedy, ak ste nám na to
						dali súhlas (to sa týka spracúvania údajov z nahraných dokumentov
						vrátane biometrických údajov). Ak sú však tieto informácie potrebné
						na uzavretie alebo plnenie zmluvy, je tento súhlas podmienkou na
						schválenie registrácie.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie osobných údajov, ktoré spracúvame{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Identifikačné údaje; kontaktné údaje, ako je e-mailová adresa a
						telefónne číslo; v prípade registrácie prostredníctvom profilu na
						sociálnej sieti vaše meno, profilová fotografia a registrovaný
						e-mail; kópia vodičského preukazu; údaje o platbe a transakcii;
						popisné údaje týkajúce sa služby; komunikácia súvisiaca so službou,
						údaje o vozidle - napr. značka a model vozidla, dátum výroby, VIN,
						evidenčné číslo, počet najazdených kilometrov atď.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Na účely overenia totožnosti z poskytnutého dokladu sa spracúvajú
						identifikačné a kontaktné údaje - presný rozsah závisí od
						konkrétneho dokladu, ale zvyčajne zahŕňa meno, pohlavie, dátum
						narodenia (vek), identifikačné číslo (ak je na doklade uvedené),
						štátnu príslušnosť, občianstvo, adresu bydliska, informácie o
						doklade - typ dokladu, krajina vydania, číslo, platnosť, strojovo
						čitateľné identifikátory, ochranné prvky, prípadne ďalšie informácie
						uvedené na doklade; informácie o fotografii - biometrické
						ukazovatele z fotografie (viacrozmerné vektory tváre), technické
						údaje - identifikátor používateľa, časová pečiatka, IP adresa a
						doména, poloha (mesto, krajina), informácie o zariadení (fotoaparát)
						a softvéri použitom na overenie.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Čas spracovania a archivácie{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Osobné údaje spracúvame počas trvania zmluvy a následne sa
						nevyhnutná časť údajov archivuje na základe oprávneného záujmu na
						ochranu našich práv (až do premlčania nárokov).
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie iných spracovateľov alebo príjemcov, ktorým môžeme
							poskytnúť osobné údaje{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše osobné údaje môžu byť v nevyhnutnom rozsahu poskytnuté našim
						obchodným partnerom, ktorí poskytujú IT a iné administratívne a
						obchodné podporné služby.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Overenie totožnosti na základe predloženého dokladu vykonáva
						spoločnosť Sum and Substance Ltd (so sídlom v Spojenom kráľovstve).
						Viac informácií o tomto spracovaní{" "}
					</span>
					<a
						href="https://sumsub.com/privacy-notice/"
						style={{ textDecoration: "none" }}
					>
						<u>
							<span style={{ fontFamily: '"Segoe UI"', color: "#0000ff" }}>
								nájdete tu
							</span>
						</u>
					</a>
					<span style={{ fontFamily: '"Segoe UI"' }}>. </span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Spolupracujeme aj so spol. Credit Check, s.r.o. pri kontrole
						registrov a záznamov.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Pôvod osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						priamo od vás, z verejne dostupných registrov, najmä z obchodných,
						exekučných a konkurzných registrov.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Prenos osobných údajov do tretích krajín alebo medzinárodným
							spoločnostiam{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci uvedeného spracovania, s výnimkou spolupráce so spol. Sum
						and Substance Ltd v Spojenom kráľovstve sa vaše osobné údaje
						neprenášajú do tretích krajín mimo Európskeho hospodárskeho
						priestoru (ďalej len "EHP") ani medzinárodným organizáciám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Automatizované rozhodovanie na základe osobných údajov{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci tohto spracovania sa nevyskytuje.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', color: "#7b7d7f" }}>
						&nbsp;
					</span>
				</p>
				<h2 style={{ marginTop: "14pt", marginBottom: "14pt" }}>
					2.
					<span
						style={{
							width: "4.5pt",
							font: '7pt "Times New Roman"',
							display: "inline-block",
						}}
					>
						&nbsp;&nbsp;{" "}
					</span>
					Prenájom vozidiel, spracovanie transakcií a platieb
				</h2>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis účelu spracovania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Údaje spracúvame, aby sme mohli spracovať žiadosti o prenájom a
						spojiť používateľov s majiteľmi. To si tiež vyžaduje, aby boli
						určité údaje (vrátane údajov vo forme hodnotení od iných
						používateľov) sprístupnené iným používateľom platformy v ich úlohe
						vodičov alebo vlastníkov, najmä vaše základné identifikačné údaje
						(meno a priezvisko), údaje o vozidle alebo iné údaje, ktoré ste nám
						dobrovoľne poskytli. Ostatné vami poskytnuté osobné údaje
						(podrobnejšie identifikačné, kontaktné a transakčné údaje) sa
						sprístupnia len v prípade, že máte záujem o uzatvorenie zmluvy o
						prenájme.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Keď použijete jedno z ponúkaných vozidiel, spracujeme informácie
						týkajúce sa vašej cesty. Vozidlá z flotily HoppyGo sú vybavené
						sledovaním polohy, aby ste ich mohli ľahko nájsť a odviezť.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis právneho základu spracúvania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Spracovanie sa vykonáva s cieľom umožniť používateľom využívať
						službu prenájmu vozidiel. Poskytnutie osobných údajov je zmluvnou
						požiadavkou nevyhnutnou na poskytovanie služieb.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie osobných údajov, ktoré spracúvame{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Identifikačné údaje; kontaktné údaje; popisné údaje; komunikácia s
						ostatnými používateľmi platformy; hodnotenie používateľa; údaje o
						polohe vozidla, ak si prenajímate naše vlastné vozidlo; údaje o
						dostupnosti vozidla; údaje o transakciách a platbách; počet
						najazdených kilometrov, informácie o prípadných priestupkoch a
						dopravných nehodách; údaje o aktuálnom stave vozidla (napr. čistota
						vozidla, technický stav vozidla atď.).
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Čas spracovania a archivácie
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Osobné údaje spracúvame počas trvania zmluvy a následne sa
						nevyhnutná časť údajov archivuje na základe oprávneného záujmu na
						ochranu našich práv (až do premlčania prípadných nárokov).
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie iných spracovateľov alebo príjemcov, ktorým môžeme
							poskytnúť osobné údaje{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Uniqa pojišťovna a.s.,{" "}
					</span>

					<span style={{ fontFamily: '"Segoe UI"' }}>
						iným používateľom platformy (len v nevyhnutnom rozsahu), obchodným
						partnerom poskytujúcim IT a iné administratívne a obchodné podporné
						služby alebo na požiadanie orgánom verejnej moci, najmä súdom,
						policajným zložkám a iným orgánom činným v trestnom konaní a orgánom
						prejednávajúcim priestupky v nevyhnutnom rozsahu a v medziach
						zákona.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Pôvod osobných údajov{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						priamo od vás alebo z vášho používania služieb.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Prenos osobných údajov do tretích krajín alebo medzinárodným
							spoločnostiam{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci uvedeného spracovania sa vaše osobné údaje neprenášajú do
						tretích krajín mimo EHP ani medzinárodným organizáciám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Automatizované rozhodovanie na základe osobných údajov{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci tohto spracovania sa nevyskytuje.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
				</p>
				<h2 style={{ marginTop: "14pt", marginBottom: "14pt" }}>
					3.
					<span
						style={{
							width: "4.5pt",
							font: '7pt "Times New Roman"',
							display: "inline-block",
						}}
					>
						&nbsp;&nbsp;{" "}
					</span>
					Archivácia osobných údajov
				</h2>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis účelu spracovania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Ak dôjde k ukončeniu zmluvy o prenájme, ktorú ste s nami uzavreli,
						napríklad zrušením vašej registrácie (či už ako vlastníka alebo
						vodiča), budeme vaše osobné údaje spracúvať aj po zrušení vášho účtu
						v rozsahu potrebnom na ochranu pred akýmikoľvek nárokmi voči nám.
						Podobne budeme uchovávať údaje aj v prípade, že ste požiadali o
						registráciu ako používateľ vlastník a vaša žiadosť bola zamietnutá
						alebo váš účet bol z nášho podnetu zrušený po vašej registrácii (či
						už ako vlastníka alebo vodiča).
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis právneho základu spracúvania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Máme oprávnený záujem na vykonávaní tohto spracovania. Tento
						oprávnený záujem spočíva v obrane proti akýmkoľvek nárokom vzneseným
						voči nám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie osobných údajov, ktoré spracúvame{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Identifikačné údaje; kontaktné údaje; popisné údaje; komunikácia s
						ostatnými používateľmi platformy a s nami; údaje o polohe (napr. ak
						nevrátite prenajaté vozidlo v dohodnutom čase); údaje o transakciách
						a platbách; údaje o stave vozidla, údaje, pre ktoré bola zamietnutá
						registrácia alebo bol zrušený používateľský účet.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Čas spracovania a archivácie{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Údaje archivujeme počas obdobia potrebného na ochranu našich práv,
						t. j. do uplynutia premlčacej lehoty pre všetky potenciálne nároky
						alebo do ich konečného vyrovnania.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie iných spracovateľov alebo príjemcov, ktorým môžeme
							poskytnúť osobné údaje{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše osobné údaje môžu byť v nevyhnutnom rozsahu spracúvané našimi
						obchodnými partnermi, ktorí poskytujú IT a iné administratívne a
						podporné služby, alebo poskytované orgánom verejnej moci, najmä
						súdom, policajným zložkám a iným orgánom činným v trestnom konaní a
						orgánom prejednávajúcim priestupky, a to v nevyhnutnom rozsahu a v
						medziach zákona.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Pôvod osobných údajov{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Priamo od vás alebo od vášho používania služby.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Prenos osobných údajov do tretích krajín alebo medzinárodným
							spoločnostiam{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci uvedeného spracovania sa vaše osobné údaje neprenášajú do
						tretích krajín mimo EHP ani medzinárodným organizáciám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Automatizované rozhodovanie na základe osobných údajov{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci tohto spracovania sa nevyskytuje.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
				</p>
				<h2 style={{ marginTop: "14pt", marginBottom: "14pt" }}>
					4.
					<span
						style={{
							width: "4.5pt",
							font: '7pt "Times New Roman"',
							display: "inline-block",
						}}
					>
						&nbsp;&nbsp;{" "}
					</span>
					Zasielanie obchodných ponúk našich produktov a služieb a informovanie
					o nových produktoch a službách
				</h2>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis účelu spracovania
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše údaje spracúvame, aby sme vás mohli informovať o obchodných
						ponukách našich produktov a služieb a o novinkách týkajúcich sa
						našich aktivít.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis právneho základu spracúvania{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Spracovanie je založené na oprávnenom záujme oznamovať aktuálne
						informácie a ponúkať ďalšie naše produkty a služby, ktoré sú pre vás
						relevantné a môžu vás zaujímať.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie osobných údajov, ktoré spracúvame{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', color: "#1e2224" }}>
						Identifikačné údaje; kontaktné údaje; údaje o účte; údaje o
						využívaní služieb; údaje o dostupných vozidlách, hodnoteniach a
						súvisiacej komunikácii.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Čas spracovania a archivácie
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše osobné údaje spracúvame počas celého trvania vášho zmluvného
						vzťahu s nami, alebo kým sa neodhlásite z odberu informačného
						bulletinu.
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp; </span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie iných spracovateľov alebo príjemcov, ktorým môžeme
							poskytnúť osobné údaje
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', color: "#1e2224" }}>
						Marketingové agentúry, analytické a štatistické spoločnosti.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Pôvod osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', color: "#1e2224" }}>
						priamo od vás alebo z používania našich služieb.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Prenos osobných údajov do tretích krajín alebo medzinárodným
							spoločnostiam{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci uvedeného spracovania sa vaše osobné údaje neprenášajú do
						tretích krajín mimo EHP ani medzinárodným organizáciám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Automatizované rozhodovanie na základe osobných údajov{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci tohto spracovania sa nevyskytuje.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Times New Roman"' }}>&nbsp;</span>
				</p>
				<h2 style={{ marginTop: "14pt", marginBottom: "14pt" }}>
					5.
					<span
						style={{
							width: "4.5pt",
							font: '7pt "Times New Roman"',
							display: "inline-block",
						}}
					>
						&nbsp;&nbsp;{" "}
					</span>
					Zabezpečenie kvality služieb, vypracovanie analýz, správ a štatistík
				</h2>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis účelu spracovania
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Na zabezpečenie správneho fungovania nami poskytovaných služieb a
						ich zlepšovanie potrebujeme spracúvať informácie o ich používaní
						vrátane osobných údajov, aby sme mohli vytvárať analýzy, správy a
						štatistiky. Ak dôjde k ukončeniu zmluvy s vami, napríklad zrušením
						vašej registrácie, vaše osobné údaje budeme naďalej spracúvať, ale
						len v minimálnom rozsahu potrebnom na tvorbu správ a štatistík.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis právneho základu spracúvania
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Máme oprávnený záujem na vykonávaní tohto spracovania. Tento
						oprávnený záujem spočíva v zabezpečení riadneho fungovania nami
						poskytovaných služieb a zlepšovaní ich kvality.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie osobných údajov, ktoré spracúvame
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Identifikačné údaje; kontaktné údaje; údaje o účte platformy;
						informácie o vozidle; údaje o používaní služieb; hodnotenia a
						súvisiaca komunikácia vrátane zaznamenaných hovorov zákazníckej
						podpory; údaje o internej kontrole a vyšetrovaní; údaje o polohe;
						sieťové identifikátory.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Čas spracovania a archivácie
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše osobné údaje spracúvame počas celého trvania vášho zmluvného
						vzťahu s nami a 3 roky po skončení zmluvy. Telefonické hovory
						používame na kontrolu kvality poskytovaných služieb po dobu 3
						mesiacov.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie iných spracovateľov alebo príjemcov, ktorým môžeme
							poskytnúť osobné údaje
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Poskytovateľ IT infraštruktúry (Spinoco Czech Republic, a.s.),
						analytické a štatistické spoločnosti.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Pôvod osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Priamo od vás alebo z vášho používania platformy.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Prenos osobných údajov do tretích krajín alebo medzinárodným
							spoločnostiam{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci uvedeného spracovania sa vaše osobné údaje neprenášajú do
						tretích krajín mimo EHP ani medzinárodným organizáciám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Automatizované rozhodovanie na základe osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci tohto spracovania sa nevyskytuje.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp;</span>
				</p>
				<h2 style={{ marginTop: "14pt", marginBottom: "14pt" }}>
					6.
					<span
						style={{
							width: "4.5pt",
							font: '7pt "Times New Roman"',
							display: "inline-block",
						}}
					>
						&nbsp;&nbsp;{" "}
					</span>
					Plnenie našich zákonných povinností
				</h2>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis účelu spracovania
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Máme povinnosti vyplývajúce z vybraných právnych predpisov, ktoré
						musíme dodržiavať a ktoré zahŕňajú spracovanie vašich osobných
						údajov. Vaše osobné údaje preto budeme spracúvať, ale len v
						nevyhnutnom rozsahu a len počas obdobia vymedzeného nižšie.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis právneho základu spracúvania
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše osobné údaje spracúvame na základe právneho titulu plnenia
						zákonných povinností, najmä v oblasti daní a účtovníctva.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie osobných údajov, ktoré spracúvame
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Identifikačné údaje; kontaktné údaje; údaje o účte služby; údaje o
						používaní služby (transakcie a platby).
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Čas spracovania a archivácie
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše osobné údaje spracúvame tak dlho, ako nám to ukladajú príslušné
						právne predpisy.
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp; </span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie iných spracovateľov alebo príjemcov, ktorým môžeme
							poskytnúť osobné údaje
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Externí daňoví, účtovní a právni poradcovia; poskytovatelia IT
						infraštruktúry.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Pôvod osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Priamo od vás alebo z vášho používania platformy.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Prenos osobných údajov do tretích krajín alebo medzinárodným
							spoločnostiam{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci uvedeného spracovania sa vaše osobné údaje neprenášajú do
						tretích krajín mimo EHP ani medzinárodným organizáciám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Automatizované rozhodovanie na základe osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci tohto spracovania sa nevyskytuje.
					</span>
				</p>
				<h2 style={{ marginTop: "14pt", marginBottom: "14pt" }}>
					7.
					<span
						style={{
							width: "4.5pt",
							font: '7pt "Times New Roman"',
							display: "inline-block",
						}}
					>
						&nbsp;&nbsp;{" "}
					</span>
					Dodržiavanie povinností voči daňovej správe
				</h2>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis účelu spracovania
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						DAC7 (smernica Rady (EÚ) 2021/514) nám ukladá zákonnú povinnosť
						zdieľať údaje používateľov platformy, ktorí majú príjem z prenájmu
						vozidiel, ak má používateľ bydlisko v členskom štáte EÚ alebo ak má
						príjem z ponuky prenájmu vozidiel v EÚ.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše údaje zhromažďujeme, aby sme mohli plniť naše zákonné
						povinnosti podľa DAC7.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Opis právneho základu spracúvania
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše osobné údaje spracúvame na základe právneho titulu plnenia
						zákonných povinností.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie osobných údajov, ktoré spracúvame
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Názov prenajímateľa, adresa bydliska alebo sídla prenajímateľa (aj
						keď nie je v Českej republike), dátum narodenia, IČ DPH/miesto
						narodenia, identifikačné číslo a údaje o všetkých priestoroch,
						bankový účet prenajímateľa, adresa, na ktorej sa vozidlo zvyčajne
						prenajíma, celkový príjem prenajímateľa z platformy za každý
						štvrťrok.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Čas spracovania a archivácie
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Podľa zákona sme povinní uchovávať vaše osobné údaje 10 rokov.
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp; </span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Kategórie iných spracovateľov alebo príjemcov, ktorým môžeme
							poskytnúť osobné údaje
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Vaše údaje budeme zdieľať s daňovými úradmi na účely dodržiavania
						DAC7. Daňová správa potom zdieľa tieto údaje s daňovými úradmi v
						členskom(-ých) štáte(-och) EÚ, kde máte bydlisko a/alebo kde sa
						nachádza vaša ponuka na prenájom vozidla.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Pôvod osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Priamo od vás alebo z vášho používania platformy.{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Prenos osobných údajov do tretích krajín alebo medzinárodným
							spoločnostiam{" "}
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci uvedeného spracovania sa vaše osobné údaje neprenášajú do
						tretích krajín mimo EHP ani medzinárodným organizáciám.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "13.5pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							Automatizované rozhodovanie na základe osobných údajov
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V rámci tohto spracovania sa nevyskytuje.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp;</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "24pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							C. OSTATNÍ PRÍJEMCOVIA ÚDAJOV
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Okrem príjemcov údajov uvedených pre jednotlivé účely spracovania
						využívame služby:
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						- ŠKODA X s.r.o., so sídlom Želetavská 1525/1, Michle, 140 00 Praha
						4, IČO: 05976359, zapísaná v obchodnom registri vedenom Mestským
						súdom v Prahe pod č. C 274001. Predmetom spolupráce sú najmä
						administratívne, marketingové, finančné alebo analytické činnosti.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V prípade, že dôjde k zmene a prenesieme naše obchodné alebo iné
						činnosti, úplne alebo čiastočne, na inú osobu (vrátane postúpenia
						zmlúv), takýto prenos bude zahŕňať osobné údaje týkajúce sa týchto
						činností. Na tieto účely môžu byť informácie (vrátane osobných
						údajov) týkajúce sa našej činnosti poskytnuté iným stranám s cieľom
						vyhodnotiť a uzavrieť transakciu. Tak by tomu bolo aj v prípade, ak
						by sme boli povinní vykonať takéto zmeny na základe zákona.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "24pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							D.AKÉ MÁTE PRÁVA?
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Pri spracúvaní osobných údajov máte tieto práva:
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						1.Prístup k spracúvaným osobným údajom.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						2.Odvolanie súhlasu so spracovaním osobných údajov, ak je
						spracúvanie založené na súhlase.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						3.Oprava nepresných alebo nesprávnych údajov alebo doplnenie
						neúplných údajov.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						4.Vymazanie osobných údajov v prípade ukončenia účelu alebo
						nezákonného spracovania. Vymazanie nemožno vykonať najmä vtedy, ak
						je spracúvanie zákonnou povinnosťou alebo je nevyhnutné na obhajobu
						našich oprávnených záujmov a práv.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						5.Obmedzenie alebo zablokovanie spracovania osobných údajov.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						6. Získajte osobné údaje v štruktúrovanom a strojovo čitateľnom
						formáte pre seba alebo pre iného prevádzkovateľa.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						7. namietať proti spracúvaniu osobných údajov na základe oprávnených
						záujmov, ak sa domnievate, že spracúvanie nie je oprávnené.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						8.Nepodliehať automatizovanému rozhodovaniu, pokiaľ nie sú splnené
						podmienky na jeho vykonanie. V prípade, že sa prijme automatizované
						individuálne rozhodnutie, ktoré má pre vás závažné dôsledky (napr.
						by viedlo k neuzatvoreniu zmluvy, odmietnutiu vyplatenia dávky,
						zmene podmienok dohodnutej služby a podobne), máte právo na
						preskúmanie takéhoto rozhodnutia, na vyjadrenie sa k nemu a prípadne
						na jeho napadnutie.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "24pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>E.</span>
					</strong>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
					</strong>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							AKO MÔŽETE UPLATNIŤ SVOJE PRÁVA?
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Na komunikáciu so spoločnými prevádzkovateľmi v súvislosti s
						ochranou údajov a na komunikáciu s úradníkom pre ochranu údajov,
						ktorého sme vymenovali, môžete použiť nasledujúce kontakty:
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', letterSpacing: "-0.25pt" }}>
						Elektronicky:{" "}
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						podpora(@)hoppygo.com
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', letterSpacing: "-0.25pt" }}>
						Telefonicky:{" "}
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>+420 220 311 769</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Aktuálne kontakty si môžete pozrieť na stránke{" "}
					</span>
					<a
						href="https://hoppygo.com/contact"
						style={{ textDecoration: "none" }}
					>
						<span className="Hyperlink" style={{ fontFamily: '"Segoe UI"' }}>
							https://hoppygo.com/contact
						</span>
					</a>
					<span style={{ fontFamily: '"Segoe UI"' }}>.</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<br />
					<span style={{ fontFamily: '"Segoe UI"' }}>
						V súvislosti s výkonom vašich práv môžu spoloční prevádzkovatelia
						požadovať primeranú náhradu nepresahujúcu náklady potrebné na
						vybavenie žiadosti, pokiaľ takéto požiadavky nie sú zjavne
						neprimerané alebo neprimerané.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "14pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "24pt",
					}}
				>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>F.</span>
					</strong>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>&nbsp; </span>
					</strong>
					<strong>
						<span style={{ fontFamily: '"Times New Roman"' }}>
							MOŽNOSŤ PODAŤ SŤAŽNOSŤ
						</span>
					</strong>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Ak nesúhlasíte so spôsobom, akým spracúvame vaše osobné údaje,
						môžete podať sťažnosť dozornému orgánu na ochranu svojich práv.
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', letterSpacing: "-0.25pt" }}>
						Adresa:{" "}
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Úrad na ochranu osobných údajov Slovenskej republiky, Hraničná 12,
						820 07 Bratislava 27, Slovenská republika
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', letterSpacing: "-0.25pt" }}>
						Telefón:{" "}
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>+ 421 2 32 31 32 14</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"', letterSpacing: "-0.25pt" }}>
						Web:{" "}
					</span>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						https://dataprotection.gov.sk/sk/{" "}
					</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>&nbsp;</span>
				</p>
				<p
					style={{
						marginTop: "14pt",
						marginBottom: "6pt",
						textAlign: "justify",
						lineHeight: "normal",
						fontSize: "12pt",
						backgroundColor: "#ffffff",
					}}
				>
					<span style={{ fontFamily: '"Segoe UI"' }}>
						Verzia dokumentu: 2.3
					</span>
				</p>
			</div>
		</>
	);
}
