import React from 'react';

/* eslint-disable */
export function PrivacyPolicyContentCsHtmFiltered() {
	return (
		<>
			<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="Generator" content="Microsoft Word 15 (filtered)" />
			<div
				className="WordSection1"
				style={{ all: "initial", margin: 0, padding: 0, overflow: "visible" }}
			>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<b>
						<span
							lang="CS"
							style={{
								fontSize: "20.0pt",
								lineHeight: "107%",
								fontFamily: '"Segoe UI",sans-serif',
							}}
						>
							Informace o zpracování osobních údajů
						</span>
					</b>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "18.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V&nbsp;tomto dokumentu se dozvíte informace o tom, jaké údaje
						sbíráme, proč je zpracováváme, jak dlouho je uchováváme a jaká práva
						na ochranu osobních údajů můžete využít.
					</span>
				</p>
				<h1 style={{ textAlign: "justify" }}>
					<span lang="CS">
						A.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
					</span>
					<span lang="CS">Správci údajů</span>
				</h1>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vzhledem k&nbsp;tomu, že platforma HoppyGo funguje v&nbsp;různých
						zemích, musíme si v&nbsp;rámci našich poboček předávat nezbytné
						informace tak, abyste naše služby mohli nerušeně využívat, ať už
						jste s&nbsp;námi kdekoli.{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Kdo všechno spravuje vaše údaje:
					</span>
				</p>
				<table
					className="MsoTableGrid"
					border={1}
					cellSpacing={0}
					cellPadding={0}
					style={{ borderCollapse: "collapse", border: "none" }}
				>
					<tbody>
						<tr>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									border: "solid windowtext 1.0pt",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										HoppyGo s.r.o.,{" "}
									</span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "9.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										IČO: 06628664, zapsaná v obchodním rejstříku vedeném
										Městským soudem v Praze pod sp. zn. C 285761
									</span>
								</p>
							</td>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									border: "solid windowtext 1.0pt",
									borderLeft: "none",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										Správce platformy HoppyGo
									</span>
								</p>
							</td>
						</tr>
						<tr>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									border: "solid windowtext 1.0pt",
									borderTop: "none",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										HoppyGo Czechia s.r.o.,{" "}
									</span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "9.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										IČO: 07799586, zapsaná v obchodním rejstříku vedeném
										Městským soudem v Praze pod sp. zn. C 307700
									</span>
								</p>
							</td>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									borderTop: "none",
									borderLeft: "none",
									borderBottom: "solid windowtext 1.0pt",
									borderRight: "solid windowtext 1.0pt",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										Správce údajů pro Česko
									</span>
								</p>
							</td>
						</tr>
						<tr>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									border: "solid windowtext 1.0pt",
									borderTop: "none",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										HoppyGo Poland SP. Z&nbsp;o o,{" "}
									</span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "9.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										KRS: 0000818732
									</span>
								</p>
							</td>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									borderTop: "none",
									borderLeft: "none",
									borderBottom: "solid windowtext 1.0pt",
									borderRight: "solid windowtext 1.0pt",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										Správce údajů pro Polsko
									</span>
								</p>
							</td>
						</tr>
						<tr>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									border: "solid windowtext 1.0pt",
									borderTop: "none",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										HoppyGo Slovakia s.r.o.
									</span>
								</p>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "9.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										IČO: 53514106
									</span>
								</p>
							</td>
							<td
								width={302}
								valign="top"
								style={{
									width: "226.55pt",
									borderTop: "none",
									borderLeft: "none",
									borderBottom: "solid windowtext 1.0pt",
									borderRight: "solid windowtext 1.0pt",
									padding: "0cm 5.4pt 0cm 5.4pt",
								}}
							>
								<p
									className="MsoNormal"
									style={{
										marginBottom: "0cm",
										textAlign: "justify",
										lineHeight: "normal",
									}}
								>
									<span
										lang="CS"
										style={{
											fontSize: "12.0pt",
											fontFamily: '"Segoe UI",sans-serif',
										}}
									>
										Správce údajů pro Slovensko
									</span>
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Konkrétně se o údaje dělíme následujícím způsobem. V&nbsp;případě,
						že se registrujete jako uživatel platformy HoppyGo, stane se
						správcem vašich osobních údajů společnost HoppyGo s.r.o., která je
						správcem platformy HoppyGo, a dále společnost HoppyGo té země, ve
						které si vytvoříte registraci.&nbsp; V&nbsp;případě, že si svou
						první registraci vytvoříte v&nbsp;České republice, bude tak HoppyGo
						s.r.o. společným správcem Vašich osobních údajů se společností
						HoppyGo Czechia s.r.o.{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Společnosti HoppyGo z&nbsp;ostatních zemí nebudou mít k&nbsp;Vašim
						osobním údajům přístup. V&nbsp;případě, že se přihlásíte do
						platformy HoppyGo z&nbsp;jiné země než ze země Vaší prvotní
						registrace, a dále v&nbsp;případě, že si budete chtít
						prostřednictvím platformy HoppyGo pronajmout automobil v&nbsp;jiné
						zemi než v&nbsp;zemi Vaší prvotní registrace, stane se společným
						správcem Vašich osobních údajů také společnost HoppyGo z&nbsp;této
						další země. To proto, abychom vám i tam mohli poskytnout naše
						služby.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "0cm",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<h1 style={{ textAlign: "justify" }}>
					<span lang="CS">
						B.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
					</span>
					<span lang="CS">Popis zpracování </span>
				</h1>
				<h1
					style={{ marginLeft: "0cm", textAlign: "justify", textIndent: "0cm" }}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontWeight: "normal",
						}}
					>
						Ke zpracování dochází v rámci této činnosti:
					</span>
				</h1>
				<h2>
					<span lang="CS">
						1.<span style={{ font: '7.0pt "Times New Roman"' }}> </span>
					</span>
					<span lang="CS">REGISTRACE UŽIVATELE a majitele vozidla</span>
				</h2>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis účelu zpracování&nbsp;</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vámi poskytnuté osobní údaje zpracováváme za tím účelem, abychom Vás
						mohli registrovat jako uživatele platformy HoppyGo, případně
						majitele vozidla a umožnili vám pronajímání nebo nabízení vozidel.
						Poskytnuté informace od uživatelů nebo majitelů vozidel také
						ověříme, abychom mohli posoudit splnění podmínek služby. Kontrola
						zahrnuje i ověření informací uživatelů v evidenci&nbsp;exekucí a
						insolvenčním rejstříku za účelem ochrany práv majitelů vozidel a
						HoppyGo a předcházení vzniku nedobytných pohledávek. V rámci
						registračního procesu budeme automatizovaně zpracovávat informace z
						vašich dokladů totožnosti a fotografií, abychom mohli zjistit a
						ověřit vaši identitu, hájit právní nároky nás a našich zákazníků a
						případně předejít podvodům. Toto zpracování zahrnuje automatické
						čtení textu, ověření pravosti dokladu a fotografií včetně
						vyhodnocení biometrických ukazatelů.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Máme právo žádost o registraci nebo i již schválenou registraci
						zrušit, zejména v&nbsp;případě zjištění exekučního nebo
						insolvenčního řízení. V návaznosti na odeslání Vaší žádosti o
						registraci od Vás jako majitele můžeme kromě informací
						z&nbsp;registrace požadovat poskytnutí dodatečných dokumentů, např.
						souhlas vlastníka či spoluvlastníků vozidla, které chcete
						prostřednictvím platformy HoppyGo pronajímat, pokud je to
						relevantní. Tyto údaje můžeme dodatečně žádat i po již
						registrovaných uživatelích v roli majitele.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis právního základu pro zpracování</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Zpracování musíme provádět pro uzavření a plnění smlouvy. Ověření
						informací v&nbsp;rejstřících a evidencích provádíme na základě
						oprávněného zájmu na provozování transparentní, bezpečné a
						spolehlivé platformy a ke kontrole právních předpokladů pro
						využívání našich služeb. Pokud je ve vztahu k některým osobním
						údajům vyžadován zvláštním právním předpisem výslovný souhlas se
						zpracováním, zpracováváme Vaše osobní údaje pouze v případě Vámi
						uděleného souhlasu (to se týká zpracování údajů z&nbsp;nahraných
						dokladů zahrnující i biometrické údaje). Tam, kde jsou informace
						nezbytné pro uzavření nebo plnění smlouvy, je tento souhlas ale
						podmínkou pro schválení registrace.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Kategorie osobních údajů, které zpracováváme</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Identifikační údaje; kontaktní údaje
					</span>
					<span lang="CS" style={{ color: "black" }}>
						{" "}
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						jako e-mailová adresa a telefonní číslo; v&nbsp;případě registrace
						prostřednictvím profilu na sociálních sítích vaše jméno, profilová
						fotografie a registrovaný email; kopie řidičského průkazu; údaje o
						platbě a transakcích; popisné údaje týkající se služby; komunikace
						týkající se služby, údaje o vozidle - např. značka a model vozidla,
						datum výroby, VIN, SPZ, počet najetých kilometrů apod.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Pro účely ověření identity z&nbsp;poskytnutého dokladu se zpracují
						identifikační a kontaktní údaje - přesný rozsah závisí na konkrétním
						dokladu, ale zpravidla jde o jméno, pohlaví, datum narození (věk),
						identifikační číslo (pokud je uvedeno na dokladu), národnost, státní
						příslušnost, adresu bydliště, informace o dokladu - typ dokladu,
						stát vydání, číslo, platnost, strojově čitelné identifikátory,
						bezpečnostní prvky, případně další informace uvedené na dokladu;
						informace o fotografii - biometrické ukazatele z fotografie
						(vícedimenzionální vektory tváře), technické údaje - identifikátor
						uživatele, časové razítko, IP adresa a doména, poloha (město, stát),
						informace o zařízení (kamera) a softwaru použitém při ověřování.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Doba zpracování a archivace</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Osobní údaje zpracováváme po dobu trvání smlouvy a následně nezbytná
						část údajů je archivována na základě oprávněného zájmu na obranu
						našich práv (do promlčení nároků).
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Kategorie dalších zpracovatelů nebo příjemců, kterým osobní údaje
						můžeme poskytnout
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše osobní údaje mohou být poskytnuty v nezbytném rozsahu našim
						obchodním partnerům poskytujícím IT a další administrativní a
						podpůrné obchodní služby.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Ověření totožnosti z&nbsp;poskytnutého dokladu provádíme
						prostřednictvím služby Sum and Substance Ltd (se sídlem ve Velké
						Británii). Více o tomto zpracování{" "}
					</span>
					<span lang="CS" style={{ color: "black" }}>
						<a href="https://sumsub.com/privacy-notice/">
							<span
								style={{
									fontSize: "12.0pt",
									fontFamily: '"Segoe UI",sans-serif',
								}}
							>
								zde
							</span>
						</a>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						.{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Spolupracujeme rovněž se spol. Credit Check, s.r.o. při kontrole
						rejstříků a evidencí.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Původ osobních údajů</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Přímo od Vás, z&nbsp;veřejně dostupných rejstříků, zejm. obchodních,
						exekučních a insolvenčních rejstříků.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Předávání osobních údajů do třetích zemí nebo mezinárodním
						společnostem
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci uvedeného zpracování se vyjma spolupráce se spol. Sum and
						Substance Ltd ve Velké Británii, vaše osobní údaje nebudou předávat
						do třetích zemí mimo Evropský hospodářský prostor („EHP“) nebo
						mezinárodním organizacím.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Automatizované rozhodování na základě osobních údajů
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci tohoto zpracování k němu nedochází.{" "}
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "#7B7D7F",
						}}
					>
						&nbsp;
					</span>
				</p>
				<h2>
					<span lang="CS">
						2.<span style={{ font: '7.0pt "Times New Roman"' }}> </span>
					</span>
					<span lang="CS">Pronájem VOZIDEL, ZPRACOVÁNÍ TRANSAKCÍ A PLATEB</span>
				</h2>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis účelu zpracování</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Údaje zpracováváme tak, abychom mohli vyřídit žádosti o pronájem
						vozidel a propojili uživatele s majiteli. K tomu je zároveň nutné,
						aby některé údaje (včetně údajů v podobě hodnocení od jiných
						uživatelů) byly zpřístupněny ostatním uživatelům platformy v roli
						řidičů nebo majitelů, zejména Vaše základní identifikační údaje
						(jméno a příjmení), údaje o vozidle, případně další údaje, které
						jste nám dobrovolně poskytl(a). Další Vámi poskytnuté osobní údaje
						(bližší identifikační, kontaktní a transakční údaje) budou
						zpřístupněny až v případě zájmu o uzavření nájemní smlouvy.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Při využití některého z&nbsp;nabízených vozidel pak zpracováváme
						informace související s&nbsp;vaší jízdou. Vozidla z HoppyGo flotily
						jsou vybavena sledováním polohy tak, abyste je mohli snadno nalézt a
						převzít.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis právního základu pro zpracování</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Zpracování se provádí, aby mohli uživatelé využívat služeb pronájmu
						vozidla. Poskytnutí osobních údajů je smluvním požadavkem nutným pro
						poskytnutí služeb.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Kategorie osobních údajů, které zpracováváme</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Identifikační údaje; kontaktní údaje; popisné údaje; komunikace
						s&nbsp;ostatními uživateli platformy; hodnocení uživatele; údaje o
						poloze vozidla, pokud si pronajímáte naše vlastní vozidlo; údaje o
						dostupnosti pronájmu vozidla; transakční údaje a údaje o platbách;
						počet ujetých kilometrů, informace o případných přestupcích a
						dopravních nehodách; údaje o aktuálním stavu vozidla (např. čistota
						vozu, technický stav vozu apod.).
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Doba zpracování a archivace</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Osobní údaje zpracováváme po dobu trvání smlouvy a následně
						&nbsp;nezbytná část údajů je archivována na základě oprávněného
						zájmu na obranu našich práv (do promlčení případných nároků).
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Kategorie dalších zpracovatelů nebo příjemců, kterým osobní údaje
						můžeme poskytnout
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Uniqa pojišťovna a.s., ostatní uživatelé platformy (jen
						v&nbsp;nezbytném rozsahu), obchodní partneři poskytující IT a další
						administrativní a podpůrné obchodní služby nebo na vyžádání orgánům
						veřejné moci, zejména soudům, policejním složkám a dalším orgánům
						činným v trestním řízení a orgánům řešícím přestupky v nezbytném
						rozsahu a v mezích zákona.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Původ osobních údajů</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Přímo od Vás nebo z vašeho využití služeb.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Předávání osobních údajů do třetích zemí nebo mezinárodním
						společnostem
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci uvedeného zpracování se Vaše osobní údaje nebudou předávat
						do třetích zemí mimo EHP nebo mezinárodním organizacím.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Automatizované rozhodování na základě osobních údajů
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci tohoto zpracování k němu nedochází.
					</span>
				</p>
				<h2 style={{ textAlign: "justify" }}>
					<span lang="CS">
						3.<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span lang="CS">ARCHIVACE OSOBNÍCH ÚDAJŮ </span>
				</h2>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis účelu zpracování</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Pokud dojde k ukončení smlouvy o obstarávání příležitosti k uzavření
						nájemní smlouvy, kterou jste s námi uzavřel(a), např. i formou
						zrušení Vaší registrace (ať již v roli majitele nebo řidiče), budeme
						Vaše osobní údaje zpracovávat i po zrušení Vašeho účtu, a to v
						rozsahu, který je nezbytný pro naší ochranu před případnými nároky
						vznesenými vůči nám. Stejně tak uchováváme údaje v&nbsp;případě, že
						jste žádali o registraci uživatele v roli majitele a Vaše žádost
						byla zamítnuta či po Vaší registraci (ať již v roli majitele nebo
						řidiče) došlo z naší iniciativy ke zrušení Vašeho účtu.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis právního základu pro zpracování</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Máme oprávněný zájem na tom, abychom toto zpracování prováděli.
						Tento oprávněný zájem spočívá v obraně před případnými nároky
						vznesenými vůči nám.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Kategorie osobních údajů, které zpracováváme</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Identifikační údaje; kontaktní údaje; popisné údaje; komunikace s
						ostatními uživateli platformy a námi; údaje o poloze (např.
						nevrátíte-li pronajatý vůz v domluveném čase); transakční údaje a
						údaje o platbách; údaje o stavu vozidla, údaje, pro které došlo
						k&nbsp;odmítnutí registrace nebo zrušení uživatelského účtu.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Doba zpracování a archivace</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Údaje archivujeme po dobu nezbytnou pro ochranu našich práv, to
						znamená do uplynutí promlčecí doby všech případných nároků nebo do
						jejich konečného vypořádání.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Kategorie dalších zpracovatelů nebo příjemců, kterým osobní údaje
						můžeme poskytnout&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše osobní údaje mohou být zpracovány v nezbytném rozsahu našimi
						obchodními partnery poskytujícími IT a další administrativní a
						podpůrné služby nebo poskytnuty na vyžádání orgánům veřejné moci,
						zejména soudům, policejním složkám a dalším orgánům činným v
						trestním řízení a orgánům řešícím přestupky v nezbytném rozsahu a v
						mezích zákona.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Původ osobních údajů</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Přímo od Vás nebo vašeho využití služby.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Předávání osobních údajů do třetích zemí nebo mezinárodním
						společnostem
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci uvedeného zpracování se Vaše osobní údaje nebudou předávat
						do třetích zemí mimo EHP nebo mezinárodním organizacím.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Automatizované rozhodování na základě osobních údajů
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci tohoto zpracování k němu nedochází.
					</span>
				</p>
				<h2 style={{ textAlign: "justify" }}>
					<span lang="CS">
						4.<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span lang="CS">
						Zasílání obchodních nabídek našich produktů a služeb a informování o
						novinkách{" "}
					</span>
				</h2>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis účelu zpracování</span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
						}}
					>
						Vaše údaje zpracováváme za tím účelem, abychom Vás informovali o
						obchodních nabídkách našich produktů a služeb a o novinkách
						týkajících se naší činnosti.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis právního základu pro zpracování </span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
						}}
					>
						Zpracování je založeno na oprávněném zájmu na sdělování aktuálních
						informací a nabízení dalších našich produktů a služeb, které jsou
						pro Vás relevantní a mohly by Vás zajímat.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Kategorie osobních údajů, které zpracováváme </span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "#1E2224",
						}}
					>
						Identifikační údaje; kontaktní údaje; údaje o účtu; údaje o
						využívání služeb; údaje o dostupných vozidlech, hodnocení a
						související komunikace.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Doba zpracování a archivace</span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
						}}
					>
						Vaše osobní údaje zpracováváme po celou dobu trvání Vašeho smluvního
						vztahu s&nbsp;námi, respektive do doby, než se z&nbsp;odběru zpráv
						odhlásíte.&nbsp;{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Kategorie dalších zpracovatelů nebo příjemců, kterým osobní údaje
						můžeme poskytnout
					</span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "#1E2224",
						}}
					>
						Marketingové agentury, analytické a statistické společnosti.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Původ osobních údajů</span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "#1E2224",
						}}
					>
						Přímo od Vás či z&nbsp;užívání našich služeb.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Předávání osobních údajů do třetích zemí nebo mezinárodním
						společnostem
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci uvedeného zpracování se Vaše osobní údaje nebudou předávat
						do třetích zemí mimo EHP nebo mezinárodním organizacím.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Automatizované rozhodování na základě osobních údajů
					</span>
					<span lang="CS" style={{ color: "#7B7D7F" }}>
						&nbsp;
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci tohoto zpracování k němu nedochází.
					</span>
				</p>
				<h2 style={{ textAlign: "justify" }}>
					<span lang="CS">
						5.<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span lang="CS">
						Zajištění kvality služeb, tvorba analýz, přehledů a statistik
					</span>
				</h2>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis účelu zpracování</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Abychom mohli řádně zajišťovat fungování námi poskytovaných služeb a
						zlepšovat je, potřebujeme zpracovávat informace o jejich používání,
						včetně osobních údajů, pro tvorbu analýz, přehledů a statistik.
						Pokud dojde k&nbsp;ukončení smlouvy s Vámi, např. i formou zrušení
						Vaší registrace, budeme Vaše osobní údaje zpracovávat i nadále, ale
						pouze v&nbsp;minimálním rozsahu nezbytném pro tvorbu přehledů a
						statistik.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis právního základu pro zpracování</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Máme oprávněný zájem na tom, abychom toto zpracování prováděli.
						Tento oprávněný zájem spočívá v zajištění řádného chodu námi
						poskytovaných služeb a ve zlepšování jejich kvality.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Kategorie osobních údajů, které zpracováváme</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Identifikační údaje; kontaktní údaje; údaje o účtu v&nbsp;rámci
						platformy; informace o vozidlech; údaje o využívání služeb;
						hodnocení a související komunikace včetně nahraných hovorů
						zákaznické podpory; údaje o vnitřní kontrole a šetření; lokalizační
						údaje; síťové identifikátory.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Doba zpracování a archivace</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše osobní údaje zpracováváme po celou dobu trvání Vašeho smluvního
						vztahu s&nbsp;námi, a dále po dobu 3 let od skončení uzavřené
						smlouvy. Telefonické hovory využíváme pro kontrolu kvality
						poskytovaných služeb po dobu 3 měsíců.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Kategorie dalších zpracovatelů nebo příjemců, kterým osobní údaje
						můžeme poskytnout
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Poskytovatel IT infrastruktury (Spinoco Czech Republic, a.s.),
						analytické a statistické společnosti.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Původ osobních údajů</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Přímo od Vás či z Vašeho užívání platformy.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="cs" style={{ color: "black" }}>
						Předávání osobních údajů do třetích zemí nebo mezinárodním
						společnostem
					</span>
					<span lang="cs" style={{ color: "#7B7D7F" }}>
						{" "}
					</span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="cs"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci uvedeného zpracování se Vaše osobní údaje nebudou předávat
						do třetích zemí mimo EHP nebo mezinárodním organizacím.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="cs" style={{ color: "black" }}>
						Automatizované rozhodování na základě osobních údajů
					</span>
					<span lang="cs" style={{ color: "#7B7D7F" }}></span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="cs"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci tohoto zpracování k němu nedochází.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<h2 style={{ textAlign: "justify" }}>
					<span lang="CS">
						6.<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span lang="CS">Plnění našich právních povinností</span>
				</h2>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis účelu zpracování</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Z vybraných právních předpisů nám plynou povinnosti, které musíme
						splnit, a které spočívají ve zpracování Vašich osobních údajů. Proto
						budeme Vaše osobní údaje zpracovávat, ale to pouze v&nbsp;nezbytně
						nutném rozsahu a pouze po níže definovanou dobu.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis právního základu pro zpracování</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše osobní údaje zpracováváme na základě právního titulu plnění
						právních povinností, zejména z oblasti daňové a účetní.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Kategorie osobních údajů, které zpracováváme</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Identifikační údaje; kontaktní údaje; údaje o účtu v&nbsp;rámci
						služby; údaje o využívání služeb (transakce a platby).
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Doba zpracování a archivace</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše osobní údaje zpracováváme po celou dobu, po kterou nám jejich
						zpracování ukládají příslušné právní předpisy.&nbsp;{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Kategorie dalších zpracovatelů nebo příjemců, kterým osobní údaje
						můžeme poskytnout
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Externí daňoví, účetní a právní poradci; poskytovatelé IT
						infrastruktury.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Původ osobních údajů</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Přímo od Vás či z Vašeho užívání platformy.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="cs" style={{ color: "black" }}>
						Předávání osobních údajů do třetích zemí nebo mezinárodním
						společnostem
					</span>
					<span lang="cs" style={{ color: "#7B7D7F" }}>
						{" "}
					</span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="cs"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci uvedeného zpracování se Vaše osobní údaje nebudou předávat
						do třetích zemí mimo EHP nebo mezinárodním organizacím.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="cs" style={{ color: "black" }}>
						Automatizované rozhodování na základě osobních údajů
					</span>
					<span lang="cs" style={{ color: "#7B7D7F" }}></span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="cs"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci tohoto zpracování k němu nedochází.
					</span>
				</p>
				<h2>
					<span lang="CS">
						7.<span style={{ font: '7.0pt "Times New Roman"' }}> </span>
					</span>
					<span lang="CS">Plnění povinností VŮČI DAŇOVÉ SPRÁVĚ</span>
				</h2>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis účelu zpracování</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Směrnice DAC7 (směrnice Rady (EU) 2021/514) nám ukládá právní
						povinnost sdílet údaje uživatelů platformy, kteří mají příjem z
						pronájmu vozidel, pokud je uživatel rezidentem členského státu EU
						nebo pokud je příjem získán z nabídky pronájmu vozidel v EU.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše údaje shromažďujeme, abychom mohli plnit naše zákonné
						povinnosti podle DAC7.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Popis právního základu pro zpracování</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše osobní údaje zpracováváme na základě právního titulu plnění
						právních povinností.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Kategorie osobních údajů, které zpracováváme</span>
				</h3>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS" style={{ fontWeight: "normal" }}>
						Jméno a příjmení nebo název pronajímatele, adresa bydliště nebo
						sídla pronajímatele (a to i když není v&nbsp;České republice), datum
						narození, DIČ/místo narození, identifikační číslo a údaje o všech
						provozovnách, bankovní účet pronajímatele, adresa, kde se vozidlo
						obvykle půjčuje, celkový příjem pronajímatele z platformy za každé
						čtvrtletí.
					</span>
				</h3>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Doba zpracování a archivace</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše osobní údaje máme podle předpisů povinnost archivovat po dobu
						10 let.&nbsp;{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">
						Kategorie dalších zpracovatelů nebo příjemců, kterým osobní údaje
						můžeme poskytnout
					</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vaše údaje budeme sdílet s úřadem daňové správy pro účely dodržování
						DAC7. Daňová správa pak tyto údaje sdílí s daňovými úřady v členském
						státě (členských státech) EU, kde máte bydliště a/nebo kde se
						nachází vaše nabídka pronájmu vozidla.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="CS">Původ osobních údajů</span>
				</h3>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Přímo od Vás či z Vašeho užívání platformy.{" "}
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="cs" style={{ color: "black" }}>
						Předávání osobních údajů do třetích zemí nebo mezinárodním
						společnostem
					</span>
					<span lang="cs" style={{ color: "#7B7D7F" }}>
						{" "}
					</span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="cs"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci uvedeného zpracování se Vaše osobní údaje nebudou předávat
						do třetích zemí mimo EHP nebo mezinárodním organizacím.
					</span>
				</p>
				<h3 style={{ textAlign: "justify" }}>
					<span lang="cs" style={{ color: "black" }}>
						Automatizované rozhodování na základě osobních údajů
					</span>
					<span lang="cs" style={{ color: "#7B7D7F" }}></span>
				</h3>
				<p className="MsoNormal" style={{ textAlign: "justify" }}>
					<span
						lang="cs"
						style={{
							fontSize: "12.0pt",
							lineHeight: "107%",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci tohoto zpracování k němu nedochází.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<h1 style={{ textAlign: "justify" }}>
					<span lang="CS">
						C.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
					</span>
					<span lang="CS">DALŠÍ PŘÍJEMCI ÚDAJŮ</span>
				</h1>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Vedle příjemců údajů uvedených u jednotlivých účelů zpracování
						využíváme služby:
					</span>
				</p>
				<p
					className="MsoListParagraph"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span lang="CS" style={{ fontSize: "12.0pt", fontFamily: "Symbol" }}>
						·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;</span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						ŠKODA X s.r.o., se sídlem Želetavská 1525/1, Michle, 140
						00&nbsp;&nbsp; Praha 4, IČO: 05976359, zapsaná v obchodním rejstříku
						vedeném Městským soudem v Praze pod sp. zn. C 274001. Předmětem
						spolupráce jsou zejména činnosti administrativní, marketingové,
						finanční, nebo analytické.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V případě, kdy by se u nás něco měnilo a převáděli jsme naši
						obchodní nebo jinou činnost jako celek nebo i její část na jinou
						osobu (včetně postoupení smluv), pak by součástí takového převodu
						byly i osobní údaje, které se této činnosti týkají. Pro tyto účely
						mohou být informace (včetně osobních údajů) týkající se naší
						činnosti sdíleny s ostatními stranami tak, aby bylo možné zhodnotit
						a uzavřít daný obchod. Stejně by to bylo i v případě, že bychom byli
						v souladu s právními předpisy nuceni takové změny udělat.
					</span>
				</p>
				<h1 style={{ textAlign: "justify" }}>
					<span lang="CS">
						D.<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span lang="CS">JAKÁ MÁTE PRÁVA?</span>
				</h1>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V rámci zpracování osobních údajů máte následující práva:
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpFirst"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						1.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Přístup ke zpracovávaným osobním údajům.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						2.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Odvolání souhlasu se zpracováním osobních údajů, je-li zpracování na
						souhlasu založené.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						3.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Opravu nepřesných či nesprávných údajů případně doplnění neúplných
						údajů.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						4.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Výmaz osobních údajů v případě zániku účelu nebo neoprávněného
						zpracování. Výmaz nemůže být proveden zejména, pokud je zpracování
						údajů zákonnou povinností, nebo nezbytné pro obhajobu našich
						legitimních zájmů a práv.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						5.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Omezení neboli blokaci zpracování osobních údajů.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						6.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Výpis osobních údajů ve strukturovaném a strojově čitelném formátu
						pro sebe, nebo pro jiného správce.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpMiddle"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						7.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Podání námitky proti zpracování osobních údajů založeném na
						oprávněných zájmech, pokud se domníváte, že zpracování není
						oprávněné.
					</span>
				</p>
				<p
					className="MsoListParagraphCxSpLast"
					style={{
						textAlign: "justify",

						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "#1E2224",
						}}
					>
						8.
						<span style={{ font: '7.0pt "Times New Roman"' }}></span>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Nebýt předmětem automatizovaného rozhodování, ledaže jsou splněny
						podmínky pro jeho provedení. V případě, kdy dochází k
						automatizovanému individuálnímu rozhodování, které pro vás má
						podstatné důsledky (např. by vedlo k neuzavření smlouvy, k zamítnutí
						výplaty plnění, změně podmínek sjednané služby apod.) máte právo
						nechat takové rozhodnutí přezkoumat, vyjádřit se k němu a případně
						ho napadnout.
					</span>
				</p>
				<h1 style={{ textAlign: "justify" }}>
					<span lang="CS">
						E.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;
						</span>
					</span>
					<span lang="CS">JAK MŮŽETE UPLATNIT SVÁ PRÁVA?</span>
				</h1>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Pro komunikaci se Společnými správci ohledně ochrany osobních údajů
						a komunikaci s&nbsp;pověřencem pro ochranu osobních údajů, kterého
						jsme jmenovali, můžete využít následující kontakty:
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
							letterSpacing: "-.25pt",
						}}
					>
						Elektronicky:
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						&nbsp;dpo(@)hoppygo.com
						<br />
						<span style={{ letterSpacing: "-.25pt" }}>Telefonicky:</span>
						&nbsp;+420 220 311&nbsp;769
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Aktuální kontakty lze ověřit na{" "}
					</span>
					<span lang="CS" style={{ color: "black" }}>
						<a href="https://hoppygo.com/contact">
							<span
								style={{
									fontSize: "12.0pt",
									fontFamily: '"Segoe UI",sans-serif',
								}}
							>
								https://hoppygo.com/contact
							</span>
						</a>
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "#7B7D7F",
						}}
					>
						<br />
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						V souvislosti s uplatněním Vašich práv mohou Společní správci
						požadovat přiměřenou úhradu nepřevyšující náklady nezbytné na
						vyřízení požadavku, pokud jsou takové požadavky zjevně nedůvodné
						nebo nepřiměřené.
					</span>
				</p>
				<h1 style={{ textAlign: "justify" }}>
					<span lang="CS">
						F.
						<span style={{ font: '7.0pt "Times New Roman"' }}>
							&nbsp;&nbsp;
						</span>
					</span>
					<span lang="CS">MOŽNOST PODÁNÍ STÍŽNOSTI</span>
				</h1>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Jestliže nesouhlasíte se způsobem, jakým Vaše osobní údaje
						zpracováváme, můžete k ochraně svých práv podat stížnost u
						dozorového úřadu.
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
							letterSpacing: "-.25pt",
						}}
					>
						Adresa:
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						&nbsp;Úřad pro ochranu osobních údajů, Pplk. Sochora 27, 170 00
						Praha 7
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
							letterSpacing: "-.25pt",
						}}
					>
						Telefon:
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						&nbsp;+420 234 665&nbsp;111
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
							letterSpacing: "-.25pt",
						}}
					>
						Web:
					</span>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						&nbsp;
					</span>
					<span lang="CS" style={{ color: "black" }}>
						<a href="http://www.uoou.cz/">
							<span
								style={{
									fontSize: "12.0pt",
									fontFamily: '"Segoe UI",sans-serif',
									color: "black",
								}}
							>
								http://www.uoou.cz
							</span>
						</a>
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{ fontSize: "12.0pt", fontFamily: '"Segoe UI",sans-serif' }}
					>
						&nbsp;
					</span>
				</p>
				<p
					className="MsoNormal"
					style={{
						marginBottom: "6.0pt",
						textAlign: "justify",
						lineHeight: "normal",
						background: "white",
					}}
				>
					<span
						lang="CS"
						style={{
							fontSize: "12.0pt",
							fontFamily: '"Segoe UI",sans-serif',
							color: "black",
						}}
					>
						Verze dokumentu: 2.3
					</span>
				</p>
			</div>
		</>
	);
}
